<!--
 * 作者：zhang
 * 时间：2024/8/13 上午10:39
 * 功能：
 *
-->

<template>
  <div class="topic">
    <!-- <van-nav-bar title="专题详情" left-arrow @click-left="$router.go(-1)" /> -->
    <!--    <div>-->
    <!--      <van-button type="primary" @click="showFilm = true">选择影片</van-button>-->
    <!--    </div>-->
    <div class="img">
      <van-image
        lazy-load
        class="img"
        fit="cover"
        :src="topicDetail.poster"
        alt=""
      ></van-image>
    </div>
    <div class="topic-content">
      <module-title>
        <template #text> 推荐列表 </template>
        <template #more>
          <span></span>
        </template>
      </module-title>
    </div>
    <div class="film-list">
      <div
        class="film-list-item"
        v-for="movie in topicDetail.films"
        :key="movie.id"
        @click="$router.push('/ruralFilm/filmDetail/' + movie.id)"
      >
        <film-item
          :movie-id="movie.id"
          :movie-img="movie.filmPicture"
          :movie-title="movie.name"
          :movie-type="movie.type"
          :movie-length="movie.filmLength"
          :movie-play-num="movie.clickCount"
          :movie-description="movie.synopsis"
        />
      </div>
    </div>

    <film-picker :show="showFilm" @close="showFilm = false" />
  </div>
</template>
<script>
import { Dialog } from 'vant'
import ModuleTitle from '@views/ruralFilm/modules/moduleTitle.vue'
import FilmItem from '@/components/movieCard'
import filmPicker from '@comp/filmPicker/index.vue'
import { api } from '@/api/index'

export default {
  name: 'Topic',
  components: { ModuleTitle, FilmItem, filmPicker },
  data() {
    return {
      showFilm: false,
      // 主题id
      id: '',
      // 专题详情
      topicDetail: {
        films: [],
        id: '1843901993011171329',
        poster:
          'https://zyt-test.oss-cn-beijing.aliyuncs.com/upload/af3ae909-dbd5-49ee-affb-7c835235ecfc_1728455459363.png',
        themeName: '杭州2专题',
      },
    }
  },
  methods: {
    // 获取专题详情
    async getThemeDetail(id) {
      // 根据id请求数据
      console.log(id)
      await api
        .getThemeDetail({
          id,
        })
        .then((res) => {
          console.log(res)
          this.topicDetail = res
        })
    },

    // async getTopicDetail() {
    //   // 根据id请求数据
    //   console.log(this.id)
    //   await api
    //     .getFilmList({
    //       themeName: this.id,
    //       sort: 0,
    //       pageNo: 1,
    //       pageSize: 100,
    //     })
    //     .then((res) => {
    //       const { records } = res
    //       console.log({ records })
    //       this.topicDetail.topicList = records
    //     })
    // },
  },
  mounted() {
    // 接受参数id
    console.log(this.$route.params)
    const id = this.$route.params.id
    if (!id) {
      Dialog({
        title: '错误',
        message: '主题id不能为空',
      })
      return
    } else {
      this.id = id
      this.getThemeDetail(id)
    }
  },
}
</script>
<style scoped lang="less">
.topic {
  &-content {
    padding: 0 16px;
  }
}
.film-list {
  padding: 0 16px;
}
</style>
